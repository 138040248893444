<template>
<div>
     <!-- <div v-if="!isMobile" v-show="!isLoading && !isShowNoData"  ref='scrollComponent'>
        <div class="match-wrapper" v-for="(o, index) in matchList" :key="index" >
            <router-link  :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="display-flex-align-center home-match__title-wrapper" >
                <div class="home-match__title">{{o.country}}</div>
				<span class="home-match__title" v-if="o.leagueName !==null">| </span>
                <div class="home-match__title" v-if="o.leagueName !==null"> {{o.leagueName}}</div>
                <div class="home-match__title" v-if="!!o.subLeagueName">: {{o.subLeagueName}}</div>
                <div class="ml-s">
                    <svg width="6" height="10" viewBox="0 0 6 10"  class="home-match__title-icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z" />
                    </svg>
                </div> 
            </router-link>
            <router-link :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`}" target="_blank"              
                class="match-container" :class="{'live-selected' : level2.isLive}" v-for="(level2, index) in o.matchList" :key="index">            
                <div class="flex-1"  v-for="(level3, index) in level2.opponents" :key="index">
                    <div class="display-flex-sb  h-100" v-if="index===0">
                         <div class="font-s match-detail__status" :class="{'match-detail__status-w-auto' : isStatusWidthAuto}">
							 <span :class="{'match-status__live': level2.matchStatus==='Live'}" > {{level2.matchStatus}}</span>							
						</div>
                        <div class=" match-detail">
                            <div class=" match-detail__name">
                                <div class="mr-1rem">{{level3.name}}</div>
                                <img class="mr-1rem img-contain" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">
                            </div>
                             <div class="icon-league home-match__icon-team" ><img :src="`${s3ImgUrl}/${level3.logo}`"></div>

                             <div class="match-detail__score">{{level3.score}}</div>													
                             <div class="match-middle__seperator">:</div>
                        </div>                     
                    </div> 
                  
                    <div class="display-flex-sb flex-1 h-100" v-if="index === 1">
                        <div class="match-detail">	
                             <div class="home-match__icon-team match-detail__score" >{{level3.score}}</div>		
                              <div class="icon-league mr-1rem" ><img :src="`${s3ImgUrl}/${level3.logo}`" ></div>
                              <img class="mr-1rem img-contain" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">
                            <span>{{level3.name}}</span>
                        </div>
                        <div v-if="level2.containLiveStream" class="home-match__icon-livevideo"  @click="handleHighlight(o, level2, $event)"></div>
                        <div v-else class="home-match__icon-highlight" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div> 
                    </div>               
                </div>
            </router-link>   
        </div>
    </div>  -->
   
   <!--  v-if="isMobile" -->
    <div>
        <div class="home-match__wrapper" v-for="(o, index) in matchList" :key="index" v-show="!isLoading && !isShowNoData">
            <div class="display-flex-align-center home-match__title-wrapper">
                <Icon  :logoUrl=o.logo class="mr-05rem home-match__title-flag"></Icon>   
                <div class="home-match__title-container">
                    <router-link :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title home-match__title-mobile" > 
                        <span v-if="!!o.leagueName">{{o.leagueName}}</span>  
                        <span  v-if="o.leagueName && o.subLeagueName"> : </span><span v-if="!!o.subLeagueName"> {{o.subLeagueName}}</span>
                    </router-link>
                    <router-link v-if="!!o.leagueName" :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title-country" >{{o.country}}</router-link>
                    <router-link v-else :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title home-match__title-mobile" >{{o.country}}</router-link>
                </div>
 
                <div class="ml-s">
                    <svg width="6" height="10" viewBox="0 0 6 10"  class="home-match__title-icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z" />
                    </svg>
                </div> 
            </div>           
            <div class="home-match__container" >                
                <router-link :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`}" target='_blank'  class="home-match__card" :class = "{'home-match__card__status-top' : isShowStatusAtTop, 'live-selected' : level2.isLive}" v-for="(level2, index) in o.matchList" :key="index"
                @click="$tools.openNewWindow($event)" >            
                
                    <!-- <p class="home-match__time">{{level2.matchStatus}}</p> -->
                     <div class=" home-match__time" :class="{'match-detail__status-w-auto' : isStatusWidthAuto}">
                         <span :class="{'match-status__live': level2.matchStatus ==='Live'}" > {{level2.matchStatus}}</span>							
                    </div>
                    <div class="display-flex-fe flex-1">
                        <div class="home-match__detail-wrapper">        
                            <div class="home-match__detail"  v-for="(level3, index) in level2.opponents" :key="index">
                                <div class="home-match__detail-opponent">
                                     <Icon   :logoUrl=level3.logo  class="home-match__icon"></Icon>
                                    <span>{{level3.name}}</span>
                                    <img class="ml-1rem" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">							
                                </div>     

                                <div class="display-flex">
                                     <div class="home-match__detail-score" >{{level3.score}}</div>
                                </div>                      
                            </div>
                        </div>
                        <div  v-show="isShowWinLose">
                            <div class="home-match__win-lose" :class="level2.winLose">{{level2.winLose}}</div>
                        </div>
                       
                        <div v-if="level2.containLiveStream" class="home-match__icon-livevideo__mobile"  @click="handleHighlight(o, level2, $event)"></div>
                        <div v-else class="home-match__icon-highlight__mobile" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div> 
                        <!-- <div class="home-match__icon-highlight__mobile" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div> -->
                    </div>       
                </router-link>
            </div>
        </div>   
    </div>

    <div v-show="isLoading && !isShowNoData"  class="skeleton-match__wrapper">
        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>
        
        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

         <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>
    </div>
    <!-- <Loading v-show="isLoading && !isShowNoData"></Loading> -->
    <div class="no-data" v-show="!isLoading && isShowNoData">{{$t('NO_MATCHES')}}</div>
 </div>

</template>

<script>

import {mapGetters,mapActions} from 'vuex'


import config from '@/js/config.js'
export default {

    props: {
        matchObj: {
            type: Array
        },
        isShowFav : {
            type:Boolean
        },
        isShowWinLose : {
            type:Boolean
        },
        WinLoseResult: {
            type: String
        },
        params:{
            type:Object
        },
        isStatusWidthAuto: {
             type:Boolean,
             default:false
        },
        isShowStatusAtTop: {
             type:Boolean,
             default:false
        }
    },

    watch:{
		params:{
			deep:true,
	        	handler(newVal,oldVal) {
             
                if (typeof newVal.leagueKey !== 'undefined') {
                    this.getFixtureByLeagueData(newVal);
                }
                else {
                    this.getMatchListData(newVal);
                }
				
			}
		}
    },

    data() {
        return {
            s3ImgUrl:config.s3ImgUrl,
            isLoading: false,
            isShowNoData: false,
            path: [],

            matchList: [],
            tmpMatchList: [],
            
            count:2,                                                                                                                                                                                                                    
        }
    },

	computed:{
           ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentSportType'
           ]),
            matchList(){ 
                //filter for matchList if score ==="", then show "?"
                let matchList = this.matchList;
                for (let level1 = 0; level1<matchList.length; level1++) {
                    for (let level2 = 0; level2<matchList[level1].matchList.length; level2++){
                        for (let level3 = 0; level3<matchList[level1].matchList[level2].opponents.length; level3++){
                            if (matchList[level1].matchList[level2].opponents[level3].score ===""){
                                matchList[level1].matchList[level2].opponents[level3].score = "-"
                            }
                        }
                    }
                }
                return matchList
            }
	},
    created() {      
         this.path=this.$route.path;         
    },

    beforeMount() {
       //window.addEventListener('scroll', this.handleScroll) 
    },
    mounted() {
    },
  
    methods:{
        ...mapActions([
            "getMatchListAsync",
            "getFixtureByLeague",
            "getMatchListByTeam"
        ]),

        replaceByDefault(e) {  
          e.target.src = `${this.s3ImgUrl}/team/default.png`
        },

        async getMatchListData(newVal){     
            let params={
				leagueIdList:newVal.leagueIdList.toString(),
				matchDate:newVal.date,
                liveOnly: newVal.isLiveOnly,
                lang:this.currentLocale,
				timeZone:this.$tools.getCurrentTimeZone()
			};

            if (!newVal.isCallInterval) {
                this.isLoading = true;    
                this.matchList = [];         
            } else {
                this.isLoading = false;
            }

           // this.isShowNoData = false;

             const result = await this.getMatchListAsync(params);
          
            if (result.result === null) {
                 this.isShowNoData = true;
            } else {
                if (result.result.leagueMatchList.length === 0) {                              
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false
                }
            }
            
           this.date = newVal.date;
           this.isLoading = false;

           this.matchList = result.result.leagueMatchList ;

        setTimeout(() => {
            this.$emit('changeMatchContentEvent',this.matchList); //broacast event to those who subscribe it 
        }, 100);
    
    	},

        async getMatchListByTeamData(newVal){
            let params={
				teamId:newVal.teamId,
				timeZone:this.$tools.getCurrentTimeZone()
			};

            if (!newVal.isCallInterval) {
                this.isLoading = true;            
            } else {
                this.isLoading = false;
            }

            const result = await this.getMatchListByTeam(params);
            this.isLoading = false;
  
            if (result.result === null) {
                  this.isShowNoData = true;
            } else {
                if (result.result.leagueMatchList.length === 0) {                
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false
                }
            }

           this.matchList =  result.result.leagueMatchList;
           this.$emit('changeMatchContentEvent',this.matchList);
    	},
       
	    async getFixtureByLeagueData(newVal){
			let params = {
				leagueKey:newVal.leagueKey,
				matchDate:newVal.matchDate,
				timeZone:this.$tools.getCurrentTimeZone()
			}
		    this.isLoading = true;
			const result = await this.getFixtureByLeague(params);
            this.isLoading = false;
			this.matchList = result.result.matchList;
           
            if (this.matchList.length == 0) {                
                this.isShowNoData = true;
            } else {
                this.isShowNoData = false
            }

			this.$emit('changeMatchContentFixtureByLeagueEvent',this.matchList);
		},
        handleMatchDetailRoutePath(o, level2, e){
            // if (level2.containLiveStream) {
            //     e.preventDefault();
            //     e.stopPropagation();
                
            //     let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;            
            //     window.open(link);
            //     return false
            // } else {
            //     this.$tools.openNewWindow(e)
            // }
        },
        //redirect to highlightpage if the match contain highlight video
        handleHighlight(o, level2, e) {
            if (level2.containLiveStream) {
                e.preventDefault();
                e.stopPropagation();
                
                let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;            
                window.open(link);
                return false
            }
            else if (level2.containHighlight) {
                e.preventDefault();
                e.stopPropagation();

                this.$router.push({ path: `/${this.currentLocale}/${this.currentSportType}/highlights/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`, query: { date: level2.matchDate } })
                return false
            } else {
                e.preventDefault();
                e.stopPropagation();
                
                let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;            
                window.open(link);
                return false
            }

           
        },
        
        selectFavMatch(o){
            o.fav =!o.fav
        },

        handleScroll(){
        
            let element = this.$refs.scrollComponent;

            if (element.getBoundingClientRect().bottom < window.innerHeight) {
                this.loadMorePosts()
            }
        },
        
        loadMorePosts(){
            if (this.tmpMatchList.length > 0) {
                let gg = this.count + 2;
                 
                 for (let i = this.count; i < gg; i++) {
                        this.matchList.push(this.tmpMatchList[i])
                    }
                    this.count = this.count+2;
                if ( this.count < this.tmpMatchList.length +2) {
                   
                }
       
            }
  
        }

     }
}
</script>

<style scoped>
    .skeleton-match__wrapper{
        width: 100%;
        height: 100%;
        padding:1rem;
        border-radius: 0.5rem;
        box-sizing: border-box;
    }

    .skeleton-match__title{
        width: 50%;
        height: 1rem;
        margin-bottom: .25rem;
    }
    .skeleton-match__content{
        width: 100%;
        height: 2rem;
        margin-bottom: 0.5rem;
    }
    .home-match__win-lose{
        background-color: green;
        border-radius: 50%;
        padding: .3rem;
        color: white;
        width: .75rem;
        border: .1rem solid white;
        height: .75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .5rem;
    }
    .home-match__win-lose.W{
        background-color: #5CD66C;    
    }
    .home-match__win-lose.L{
        background-color: #CF283E;    
    }
    .home-match__win-lose.D{
        background-color:   #ED9C5A;    
    }
    .match-wrapper{
        margin-bottom: 1.2rem;
    }
    .match-container{
        display: flex;
        background-color: var(--color-theme);
        color: #CFD9DE;
        margin-bottom: .5rem;
        padding: .6rem .91rem;
        border-radius: .5rem;
        font-size: .88rem;
        text-align: left;
        min-width: 36rem;
        box-sizing: border-box;
    }
    .match-container:hover{
        background-color: var(--bg-hover);
    }
    .match-container.live-selected{
        border-left: .4rem solid var(--bg-selected);
        padding-left: 0.5rem;
    }
	.match-status__live{
		background-color: #CF283E;
		padding: .2rem;
    	border-radius: .3rem;
		font-size:.65rem
	}
    .match-detail{
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .match-detail__status{
          width: 1.6rem;
    }
    .match-detail__status-w-auto{
        width :auto;
    }
    .match-detail__score{
        width: 1rem;
        text-align: center;
    }
    .match-detail__name{
        width: 9rem;     
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }
    .match-middle{
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        color:white;
    }
    .match-middle__seperator{
        margin: 0 .5rem;
        font-weight: bold;
        font-weight: bold;
    }

</style>
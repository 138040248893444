<template>
      <router-link :to="`/${currentLocale}`">
        <svg width="72" height="46" viewBox="0 0 72 46" class="main-logo" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1335 9.89844H15.0283L23.4939 25.5453H19.4483L17.8425 22.5963H9.30356L10.9133 19.6216H16.2327L12.5693 12.8694L5.71352 25.5453H1.66797L10.1335 9.89844Z" fill="white"/>
            <path d="M24.7562 24.8227C24.0845 24.3372 23.7448 23.6255 23.7293 22.6875V20.8991H27.1804V21.348C27.2267 21.8115 27.3387 22.1218 27.5124 22.286C27.6861 22.4503 27.9447 22.5452 28.2922 22.578H37.9621C38.2787 22.578 38.5141 22.5525 38.6724 22.5014C38.8307 22.4503 38.9581 22.3225 39.0507 22.1218C39.1434 21.921 39.1935 21.5962 39.1935 21.1509V20.6363C39.1935 20.1874 39.1472 19.8662 39.0507 19.6654C38.9542 19.4647 38.8307 19.3369 38.6724 19.2858C38.5141 19.2347 38.2787 19.2092 37.9621 19.2092L27.1341 19.2311C26.5512 19.2311 26.003 19.1034 25.4896 18.8515C24.9762 18.5997 24.5593 18.2347 24.235 17.7566C23.9108 17.2784 23.7409 16.72 23.7255 16.0813V13.0628C23.7409 12.4205 23.9108 11.862 24.235 11.3876C24.5593 10.9094 24.9762 10.5445 25.4896 10.2926C26.003 10.0408 26.5512 9.91303 27.1341 9.91303H39.1897C40.1046 9.91303 40.8843 10.1357 41.529 10.5846C42.1737 11.0335 42.4979 11.7927 42.4979 12.8658V14.5629H39.1897V14.4279C39.1897 13.9498 39.1472 13.5994 39.0584 13.3767C38.9735 13.1541 38.85 13.0154 38.6917 12.9643C38.5334 12.9132 38.2902 12.8877 37.9583 12.8877H28.2922C27.8019 12.9169 27.497 13.0738 27.3696 13.3585C27.2422 13.6432 27.1804 14.0447 27.1804 14.5666C27.1804 15.0885 27.2383 15.49 27.358 15.7747C27.4777 16.0594 27.7633 16.2163 28.2227 16.2455H39.1897C40.1046 16.2455 40.8843 16.4682 41.529 16.9171C42.1737 17.366 42.4979 18.1252 42.4979 19.1982V22.6182C42.4979 23.6912 42.1737 24.4504 41.529 24.8993C40.8843 25.3482 40.1007 25.5709 39.1897 25.5709H27.1804C26.2346 25.549 25.424 25.3045 24.7562 24.8227Z" fill="white"/>
            <path d="M43.9185 9.89844H47.3927V25.5453H43.9185V9.89844Z" fill="white"/>
            <path d="M57.0395 9.89844H61.9343L70.3999 25.5453H66.3582L64.7523 22.5963H56.2134L57.8232 19.6216H63.1426L59.4792 12.8694L52.6234 25.5453H48.5817L57.0395 9.89844Z" fill="white"/>
            <path d="M11.1052 35.9212C10.7628 35.6751 10.5858 35.3131 10.5781 34.8354V33.9233H12.3441V34.1513C12.3672 34.3865 12.4249 34.5458 12.5134 34.6291C12.6019 34.7123 12.7366 34.763 12.9136 34.7775H17.8616C18.0232 34.7775 18.1424 34.763 18.2232 34.7376C18.304 34.7123 18.3694 34.6472 18.4156 34.5458C18.4656 34.4445 18.4887 34.278 18.4887 34.0499V33.7893C18.4887 33.5613 18.4656 33.3984 18.4156 33.2935C18.3656 33.1921 18.304 33.127 18.2232 33.1016C18.1424 33.0763 18.0232 33.0618 17.8616 33.0618L12.321 33.0727C12.0209 33.0727 11.7439 33.0075 11.4784 32.8808C11.2129 32.7542 11.0013 32.566 10.8359 32.3234C10.6704 32.0809 10.5819 31.795 10.5742 31.4693V29.931C10.5819 29.6052 10.6704 29.3193 10.8359 29.0768C11.0013 28.8343 11.2168 28.6497 11.4784 28.5194C11.74 28.3891 12.0209 28.3275 12.321 28.3275H18.4926C18.962 28.3275 19.3583 28.4398 19.6892 28.6678C20.02 28.8958 20.1855 29.2831 20.1855 29.8296V30.6947H18.4926V30.6259C18.4926 30.3834 18.4695 30.2061 18.4271 30.0902C18.3848 29.9744 18.3194 29.9056 18.2386 29.8803C18.1578 29.855 18.0347 29.8405 17.8654 29.8405H12.9174C12.6673 29.855 12.5096 29.9346 12.4441 30.0794C12.3787 30.2242 12.348 30.4268 12.348 30.6947C12.348 30.9625 12.3787 31.1652 12.4403 31.31C12.5019 31.4548 12.6481 31.5344 12.8828 31.5489H18.4964C18.9658 31.5489 19.3621 31.6611 19.693 31.8891C20.0239 32.1171 20.1893 32.5044 20.1893 33.051V34.7919C20.1893 35.3385 20.0239 35.7258 19.693 35.9538C19.3621 36.1818 18.962 36.294 18.4964 36.294H12.348C11.8632 36.2904 11.4515 36.1673 11.1052 35.9212Z" fill="#01C3F7"/>
            <path d="M20.9127 30.6911H22.6787V32.3741H28.0269C28.3078 32.3741 28.5001 32.2909 28.6002 32.1244C28.7002 31.9579 28.7502 31.7443 28.7502 31.4874V30.6802C28.7656 30.1192 28.5425 29.8369 28.073 29.8369H20.9127V28.3239H28.5963C28.9041 28.3239 29.2042 28.3891 29.4967 28.5158C29.7891 28.6461 30.0315 28.8343 30.2239 29.0804C30.4124 29.3265 30.5124 29.6089 30.5201 29.9274V31.9868C30.5124 32.3452 30.4201 32.6673 30.2431 32.9532C30.0661 33.2392 29.8276 33.4708 29.5274 33.6373C29.2273 33.8038 28.8887 33.8871 28.5117 33.8871H22.6826V36.2904H20.9165V30.6911H20.9127Z" fill="#01C3F7"/>
            <path d="M31.7744 35.9212C31.432 35.6751 31.255 35.3131 31.2473 34.8354V30.6911H33.0133V34.1513C33.0364 34.3866 33.0941 34.5458 33.1826 34.6291C33.2711 34.7123 33.402 34.763 33.5712 34.7775H38.5308C38.6924 34.7775 38.8117 34.763 38.8925 34.7376C38.9733 34.7123 39.0387 34.6472 39.0848 34.5458C39.1349 34.4445 39.1579 34.278 39.1579 34.0499V30.6223C39.1579 30.3798 39.1349 30.2024 39.0925 30.0866C39.0502 29.9708 38.9848 29.902 38.9002 29.8767C38.8155 29.8514 38.6924 29.8369 38.5308 29.8369H31.2473C31.2781 29.3736 31.4666 29.0044 31.8052 28.7329C32.1438 28.4615 32.5401 28.3239 32.9903 28.3239H39.1502C39.6273 28.3239 40.0275 28.4361 40.3545 28.6642C40.6816 28.8922 40.8432 29.2795 40.8432 29.826V34.7883C40.8432 35.3349 40.6816 35.7221 40.3545 35.9502C40.0275 36.1782 39.6273 36.2904 39.1502 36.2904H33.0018C32.5247 36.2904 32.1169 36.1673 31.7744 35.9212Z" fill="#01C3F7"/>
            <path d="M41.5704 36.2904V30.6911H43.3364V32.3741H48.6846C48.9654 32.3741 49.1578 32.2909 49.2579 32.1244C49.3579 31.9579 49.4079 31.7443 49.4079 31.4874V30.6802C49.4233 30.1192 49.2002 29.8369 48.7307 29.8369H41.5704V28.3239H49.254C49.5618 28.3239 49.8619 28.3891 50.1544 28.5158C50.4468 28.6461 50.6892 28.8343 50.8815 29.0804C51.0701 29.3265 51.1701 29.6089 51.1778 29.9274V32.1352C51.1624 32.6275 51.0047 33.0437 50.7046 33.3803C50.4044 33.717 50.0081 33.8871 49.508 33.8871L51.1778 36.2904H49.1578L47.4995 33.8871H43.3364V36.2904H41.5704Z" fill="#01C3F7"/>
            <path d="M51.9012 28.3203H61.497V29.9961H51.9012V28.3203ZM55.8219 30.8503H57.5879V36.2904H55.8219V30.8503Z" fill="#01C3F7"/>
        </svg>
     </router-link> 
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
   computed:{
        ...mapGetters([              
            'currentLocale'
        ]),
    }
    
}
</script>

<style>
.main-logo{
    width: inherit;
    height: inherit;
}
</style>